/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function InfoIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7732 1C6.69806 1 1.77319 5.92487 1.77319 12C1.77319 18.0751 6.69806 23 12.7732 23C18.8483 23 23.7732 18.0751 23.7732 12C23.7732 5.92487 18.8483 1 12.7732 1ZM12.7732 7C12.2209 7 11.7732 7.44772 11.7732 8C11.7732 8.55228 12.2209 9 12.7732 9H12.7832C13.3355 9 13.7832 8.55228 13.7832 8C13.7832 7.44772 13.3355 7 12.7832 7H12.7732ZM13.7732 12C13.7732 11.4477 13.3255 11 12.7732 11C12.2209 11 11.7732 11.4477 11.7732 12V16C11.7732 16.5523 12.2209 17 12.7732 17C13.3255 17 13.7732 16.5523 13.7732 16V12Z"
        fill="#4A5DBF"
      />
    </svg>
  );
}
