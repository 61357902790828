/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function DownloadIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 2.01558C13 1.98928 13 1.97612 12.9989 1.95227C12.9753 1.4585 12.541 1.02448 12.0473 1.00112C12.0234 0.999992 12.0156 0.999995 12 1H8.75866C7.95372 0.99999 7.28935 0.999981 6.74818 1.0442C6.18608 1.09012 5.66938 1.18869 5.18404 1.43598C4.43139 1.81947 3.81947 2.43139 3.43598 3.18404C3.18869 3.66938 3.09012 4.18608 3.0442 4.74818C2.99998 5.28936 2.99999 5.95372 3 6.75867V17.2413C2.99999 18.0463 2.99998 18.7106 3.0442 19.2518C3.09012 19.8139 3.18869 20.3306 3.43598 20.816C3.81947 21.5686 4.43139 22.1805 5.18404 22.564C5.66938 22.8113 6.18608 22.9099 6.74818 22.9558C7.28937 23 7.95372 23 8.75868 23H15.2413C16.0463 23 16.7106 23 17.2518 22.9558C17.8139 22.9099 18.3306 22.8113 18.816 22.564C19.5686 22.1805 20.1805 21.5686 20.564 20.816C20.8113 20.3306 20.9099 19.8139 20.9558 19.2518C21 18.7106 21 18.0463 21 17.2413V9.99994C21 9.98441 21 9.97664 20.9989 9.95282C20.9756 9.45899 20.5415 9.02471 20.0477 9.00115C20.0238 9.00001 20.0107 9.00001 19.9845 9.00001L15.5681 9.00001C15.3157 9.00004 15.0699 9.00007 14.8618 8.98307C14.6332 8.96439 14.3634 8.92032 14.092 8.78202C13.7157 8.59028 13.4097 8.28432 13.218 7.90799C13.0797 7.63657 13.0356 7.36683 13.017 7.13824C12.9999 6.93008 13 6.68429 13 6.43191L13 2.01558ZM12 12C12.5523 12 13 12.4477 13 13V16.5858L14.2929 15.2929C14.6834 14.9024 15.3166 14.9024 15.7071 15.2929C16.0976 15.6834 16.0976 16.3166 15.7071 16.7071L12.7071 19.7071C12.5196 19.8946 12.2652 20 12 20C11.7348 20 11.4804 19.8946 11.2929 19.7071L8.29289 16.7071C7.90237 16.3166 7.90237 15.6834 8.29289 15.2929C8.68342 14.9024 9.31658 14.9024 9.70711 15.2929L11 16.5858V13C11 12.4477 11.4477 12 12 12Z"
        fill={color}
        // fillOpacity="0.64"
      />
      <path
        d="M18.908 6.99999C19.2016 7 19.3484 7 19.4686 6.92627C19.6385 6.82215 19.74 6.57684 19.6935 6.38313C19.6605 6.24598 19.5648 6.15033 19.3733 5.95904L16.041 2.62672C15.8497 2.43523 15.754 2.33949 15.6169 2.30652C15.4232 2.25996 15.1779 2.3615 15.0737 2.53134C15 2.65161 15 2.79842 15 3.09204L15 6.19996C15 6.47998 15 6.61999 15.0545 6.72694C15.1024 6.82102 15.1789 6.89751 15.273 6.94545C15.38 6.99995 15.52 6.99995 15.8 6.99995L18.908 6.99999Z"
        fill={color}
        // fillOpacity="0.64"
      />
    </svg>
  );
}
