/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function File05Icon({
  color = "#2058BB",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7732 2.01558C13.7732 1.98928 13.7732 1.97612 13.7721 1.95227C13.7485 1.4585 13.3142 1.02448 12.8205 1.00112C12.7966 0.999992 12.7888 0.999995 12.7732 1H9.53185C8.72692 0.99999 8.06255 0.999981 7.52137 1.0442C6.95927 1.09012 6.44257 1.18869 5.95723 1.43598C5.20459 1.81947 4.59266 2.43139 4.20917 3.18404C3.96188 3.66938 3.86331 4.18608 3.81739 4.74818C3.77317 5.28936 3.77318 5.95372 3.77319 6.75867V17.2413C3.77318 18.0463 3.77317 18.7106 3.81739 19.2518C3.86331 19.8139 3.96188 20.3306 4.20917 20.816C4.59266 21.5686 5.20459 22.1805 5.95723 22.564C6.44257 22.8113 6.95927 22.9099 7.52137 22.9558C8.06256 23 8.72691 23 9.53187 23H16.0145C16.8195 23 17.4838 23 18.025 22.9558C18.5871 22.9099 19.1038 22.8113 19.5892 22.564C20.3418 22.1805 20.9537 21.5686 21.3372 20.816C21.5845 20.3306 21.6831 19.8139 21.729 19.2518C21.7732 18.7106 21.7732 18.0463 21.7732 17.2413V9.99994C21.7732 9.98441 21.7732 9.97664 21.7721 9.95282C21.7487 9.45899 21.3147 9.02471 20.8209 9.00115C20.797 9.00001 20.7839 9.00001 20.7577 9.00001L16.3413 9.00001C16.0889 9.00004 15.8431 9.00007 15.635 8.98307C15.4064 8.96439 15.1366 8.92032 14.8652 8.78202C14.4889 8.59028 14.1829 8.28432 13.9912 7.90799C13.8529 7.63657 13.8088 7.36683 13.7901 7.13824C13.7731 6.93008 13.7732 6.68429 13.7732 6.43191L13.7732 2.01558ZM8.77319 13C8.22091 13 7.77319 13.4477 7.77319 14C7.77319 14.5523 8.22091 15 8.77319 15H16.7732C17.3255 15 17.7732 14.5523 17.7732 14C17.7732 13.4477 17.3255 13 16.7732 13H8.77319ZM8.77319 17C8.22091 17 7.77319 17.4477 7.77319 18C7.77319 18.5523 8.22091 19 8.77319 19H14.7732C15.3255 19 15.7732 18.5523 15.7732 18C15.7732 17.4477 15.3255 17 14.7732 17H8.77319Z"
        fill={color}
      />
      <path
        d="M19.6812 6.99999C19.9748 7 20.1216 7 20.2418 6.92627C20.4117 6.82215 20.5132 6.57684 20.4667 6.38313C20.4337 6.24598 20.338 6.15033 20.1465 5.95904L16.8142 2.62672C16.6229 2.43523 16.5272 2.33949 16.3901 2.30652C16.1964 2.25996 15.9511 2.3615 15.8469 2.53134C15.7732 2.65161 15.7732 2.79842 15.7732 3.09204L15.7732 6.19996C15.7732 6.47998 15.7732 6.61999 15.8277 6.72694C15.8756 6.82102 15.9521 6.89751 16.0462 6.94545C16.1531 6.99995 16.2932 6.99995 16.5732 6.99995L19.6812 6.99999Z"
        fill={color}
      />
    </svg>
  );
}
