/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function UploadIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_6884_1195)">
        <rect
          x="2.77319"
          y="1"
          width="40"
          height="40"
          rx="8"
          fill="white"
          shapeRendering="crispEdges"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.2732 13C16.6833 13 13.7732 15.9101 13.7732 19.5C13.7732 21.2909 14.4962 22.911 15.6685 24.0877C16.0584 24.479 16.0572 25.1121 15.666 25.5019C15.2747 25.8917 14.6416 25.8906 14.2517 25.4993C12.7211 23.9631 11.7732 21.8413 11.7732 19.5C11.7732 14.8056 15.5788 11 20.2732 11C23.3179 11 25.9876 12.6014 27.4876 15.0035C30.9782 15.1166 33.7732 17.9819 33.7732 21.5C33.7732 23.5188 32.8516 25.3233 31.41 26.5139C30.9841 26.8656 30.3538 26.8055 30.0021 26.3796C29.6505 25.9538 29.7106 25.3235 30.1364 24.9718C31.1378 24.1448 31.7732 22.897 31.7732 21.5C31.7732 19.0147 29.7585 17 27.2732 17C26.7095 17 26.173 16.7063 25.8778 16.2054C24.7461 14.285 22.6591 13 20.2732 13ZM22.0661 20.2929C22.4566 19.9024 23.0898 19.9024 23.4803 20.2929L27.4803 24.2929C27.8708 24.6834 27.8708 25.3166 27.4803 25.7071C27.0898 26.0976 26.4566 26.0976 26.0661 25.7071L23.7732 23.4142V30C23.7732 30.5523 23.3255 31 22.7732 31C22.2209 31 21.7732 30.5523 21.7732 30V23.4142L19.4803 25.7071C19.0898 26.0976 18.4566 26.0976 18.0661 25.7071C17.6756 25.3166 17.6756 24.6834 18.0661 24.2929L22.0661 20.2929Z"
          fill="#0D0D19"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_6884_1195"
          x="0.773193"
          y="0"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0587703 0 0 0 0 0.111232 0 0 0 0 0.127799 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6884_1195"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6884_1195"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
